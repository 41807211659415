<template>
  <div class="content">
    <img
      class="content-topimg"
      src="https://ppyos.xijiuyou.com/imgs/invite/ppywicon.png"
      alt=""
    />
    <img
      :style="windowHeight > 700 ? 'margin-top:48px;' : ''"
      class="content-topimg"
      src="https://ppyos.xijiuyou.com/imgs/invite/yqhyxs.png"
      alt=""
    />
    <img
      :style="windowHeight > 700 ? 'margin-top:10px;' : ''"
      class="content-topimg"
      src="https://ppyos.xijiuyou.com/imgs/invite/lwppyw.png"
      alt=""
    />

    <div class="btn" :style="windowHeight > 700 ? 'bottom:35px;' : ''">
      <img
        @click="rulesbool = true"
        src="https://ppyos.xijiuyou.com/imgs/invite/qrcodeinv.png"
        alt=""
      />
      <img
        @click="isshowpopup = true"
        src="https://ppyos.xijiuyou.com/imgs/invite/codeInv.png"
        alt=""
      />
    </div>
    <!-- 保持二维码图片 -->
    <xwpopup :isshowpopup="rulesbool" :hiddenClose="true">
      <div class="qrcode-conetnt" @click.self="closeback">
        <div class="qrcode-conetnt-top" ref="bill">
          <div class="toplogo">
            <div>
              <img
                :src="
                  iconurl
                    ? iconurl
                    : 'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'
                "
                alt=""
                crossorigin="anonymous"
              />
              <div>
                <p>{{ nickname }}</p>
                <p>我的邀请码：{{ code }}</p>
              </div>
            </div>
            <img
              class="invite-card-tips"
              src="https://ppyos.xijiuyou.com/imgs/invite/invite-card-tips.png"
              alt=""
              crossorigin="anonymous"
            />
          </div>
          <div></div>
          <!-- <img
            class="topimg"
            src="https://ppyos.xijiuyou.com/imgs/invite/ppywenmonytog.png"
            alt=""
            crossorigin="anonymous"
          /> -->
          <div style="er-list-center">
            <div class="er-list">
              <div>
                <img
                  src="https://ppyos.xijiuyou.com/imgs/invite/invite-up20.png"
                  alt=""
                  crossorigin="anonymous"
                />
                <p>
                  同款游戏 <br />
                  奖励多20%
                </p>
              </div>
              <div>
                <img
                  src="https://ppyos.xijiuyou.com/imgs/invite/invite-1000card.png"
                  alt=""
                  crossorigin="anonymous"
                />
                <p>
                  1000元收益卡 <br />
                  最高再奖20%
                </p>
              </div>
            </div>
            <qrcode
              :url="qrcodeUrl"
              :wid="107"
              :hei="107"
            />
            <img
              class="logo"
              src="https://ppyos.xijiuyou.com/imgs/invite/icon-title.png"
              alt=""
              crossorigin="anonymous"
            />
          </div>
          <img
            class="backimg"
            src="https://ppyos.xijiuyou.com/imgs/invite/invite-thres-bg.png"
            alt=""
            crossorigin="anonymous"
          />
        </div>
        <div class="bottombtn" @click="erqrcode">
          <img
            src="https://ppyos.xijiuyou.com/imgs/invite/saveicon.png"
            alt=""
          />
          <span>保存二维码图片</span>
        </div>
      </div>
    </xwpopup>
    <!-- 分享弹窗 -->
    <xwpopup
      :isshowpopup="isshowpopup"
      @close="closeHandle()"
      :hiddenClose="false"
    >
      <div class="share">
        <img
          src="https://ppyos.xijiuyou.com/imgs/invite/sharetofri.png"
          alt=""
        />
        <div class="share-center">
          <div class="share-center-le tag-read" :data-clipboard-text="qrcodeUrl" @click="copy">
            <p>专属链接</p>
            <div>{{ qrcodeUrl }}</div>
            <p >复制链接</p>
          </div>
          <div class="share-center-ri tag-reads" :data-clipboard-text="code" @click="copys">
            <p>我的邀请码</p>
            <div>{{ code }}</div>
            <p>复制邀请码</p>
          </div>
        </div>
      </div>
    </xwpopup>
    <img
      class="cheesbox"
      :style="windowHeight > 700 ? 'top:80px;' : ''"
      src="https://ppyos.xijiuyou.com/imgs/invite/cheesbox.png"
      alt=""
    />
  </div>
</template>
<script>
import qrcode from "vue_qrcodes";
import html2canvas from "html2canvas";
import xwpopup from "../components/Popupbox.vue";
import Clipboard from "clipboard";
import home from "../api/home";
import { Toast } from "vant";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    qrcode,
    xwpopup,
  },
  data() {
    return {
      qrcodeUrl: "",
      iconurl: "",
      code: "",
      nickname: "",
      rulesbool: false,
      isshowpopup: false,
      windowHeight: document.documentElement.clientHeight,
    };
  },
  mounted() {
    home.myInviteLink().then((res) => {
      this.qrcodeUrl = res.data.inviteLink;
      this.iconurl = res.data.avatar;
      this.code = res.data.code;
      this.nickname = res.data.nickname;
    });
  },
  methods: {
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", () => {
        Toast("复制成功");
      });
    },
    copys() {
      var clipboard = new Clipboard(".tag-reads");
      clipboard.on("success", () => {
        Toast("复制成功");
      });
    },
    closeHandle() {
      this.isshowpopup = false;
    },
    closeback() {
      this.rulesbool = false;
    },
    erqrcode() {
      this.rulesbool = false;
      html2canvas(this.$refs.bill, {
        useCORS: true,
      }).then((res) => {
        //this.img = res.toDataURL("image/png");
        try{
          window.android.saveImageBy64(res.toDataURL("image/png"));
        }catch(e){
          Toast('您当前app版本过低请更新app');
        }
        // this.downloadFile("二维码.png", res.toDataURL("image/png"));
      });
    },
    downloadFile(fileName, content) {
      let aLink = document.createElement("a");
      let blob = this.base64ToBlob(content); //new Blob([content]);

      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);

      // aLink.dispatchEvent(evt);
      aLink.click();
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
  },
};
</script>
<style scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background: url("https://ppyos.xijiuyou.com/imgs/invite/bluebg.png")
    no-repeat;
  background-size: 100%;
  position: relative;
  text-align: center;
}
.btn {
  position: absolute;
  display: flex;
  bottom: 0px;
  padding-bottom: 20px;
  width: 100%;
  justify-content: center;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0), #ffffff);
}
.btn img {
  width: calc(50% - 10px);
  height: 86px;
  margin: 0 3px;
}
.content-topimg:nth-child(1) {
  width: 129px;
}
.content-topimg:nth-child(2) {
  width: calc(100% - 40px);
  margin-top: 38px;
  position: relative;
  z-index: 1;
}
.content-topimg:nth-child(3) {
  width: calc(100% - 50px);
  position: relative;
  z-index: 1;
}
.qrcode-conetnt {
  width: 100%;
  height: 100%;
}
.qrcode-conetnt-top {
  width: 284.97px;
  margin: 0 auto;
  margin-top: 0.625rem;
  background: url("https://ppyos.xijiuyou.com/imgs/invite/invite-thres-bg.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 10px;
  padding-bottom: 4.25rem;
  position: relative;
}
.qrcode-conetnt-top .toplogo:nth-child(1) {
  width: calc(100% - 18px);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  margin: 0 auto 0;
  padding: 10px;
  position: relative;
  z-index: 1;
}
.qrcode-conetnt-top .topimg:nth-child(2) {
  width: calc(100% - 20px);
  margin-top: 10px;
  position: relative;
  z-index: 1;
}
.qrcode-conetnt-top div:nth-child(3) {
  width: 180px;
  background: #ffffff;
  border-radius: 0.625rem;
  padding: 0 4px;
  padding-top: 15px;
  box-sizing: border-box;
  margin: 15px auto 0;
  text-align: center;
  padding-bottom: 7px;
  position: relative;
  margin-bottom: 70px;
  z-index: 1;
}
.qrcode-conetnt-top div:nth-child(3) img {
  margin: 0;
}
.er-list {
  display: flex;
  font-size: 12px;
  justify-content: space-around;
  font-weight: normal;
  color: #ff5675;
  margin-bottom: 15px;
  line-height: 17px;
}
.er-list-center {
  position: relative;
  z-index: 1;
}
.er-list img {
  width: 2.8125rem;
}
.toplogo div {
  display: flex;
  align-items: center;
  padding-left: 6px;
}
.toplogo div img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 7px;
}
.toplogo div div {
  display: block;
  text-align: left;
  padding: 0;
}
.toplogo div div p {
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.toplogo div p:nth-child(2) {
  font-size: 12px;
  margin-top: 4px;
}
.bottombtn {
  width: 210px;
  height: 48px;
  background: #ffffff;
  border-radius: 36px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.bottombtn img {
  width: 17px;
  margin-right: 5px;
}
.share {
  margin-top: 150px;
}
.share img {
  width: calc(100% - 88px);
}
.share-center {
  width: calc(100% - 70px);
  height: 198px;
  background: linear-gradient(180deg, #fff5d8 0%, #ffffff 100%);
  box-shadow: 0px 2px 0px 0px #ffffff;
  border-radius: 15px;
  margin: -5px auto 0;
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
  padding-top: 23px;
}
.share-center-le {
  padding: 0 5px;
  box-sizing: border-box;
  width: calc(50% - 7px);
  height: 160px;
  background: #ffb7c7;
  box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 0.5) inset,
    0px -3px 3px 0px rgba(255, 98, 137, 0.6) inset;
  border-radius: 15px;
  padding-top: 10px;
}
.share-center-le p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #000000;
}
.share-center-le div:nth-child(2) {
  width: 100%;
  height: 56px;
  background: #ff8299;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 10px;
  padding: 0 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  box-sizing: border-box;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}
.share-center-le p:nth-child(3) {
  margin: 0 auto;
  margin-top: 10px;
  width: calc(100% - 5px);
  height: 40px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-radius: 23px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-center-ri {
  padding: 0 5px;
  box-sizing: border-box;
  width: calc(50% - 7px);
  height: 160px;
  background: #ffd380;
  box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 0.5) inset,
    0px -3px 3px 0px rgba(255, 131, 0, 0.7) inset;
  border-radius: 15px;
  padding-top: 10px;
}
.share-center-ri p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #000000;
}
.share-center-ri div:nth-child(2) {
  width: 100%;
  height: 56px;
  background: #ffb146;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-center-ri p:nth-child(3) {
  margin: 0 auto;
  margin-top: 10px;
  width: calc(100% - 5px);
  height: 40px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-radius: 23px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}
.backimg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.cheesbox {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0px;
}
.logo {
  width: 90px;
  margin-top: 9px!important;
}
.invite-card-tips {
  width: calc(100% - 16px);
  margin-top: 12px;
}
</style>
<style>
#app {
  background: #ffffff;
}
</style>